import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    rail: _ctx.rail,
    color: "#F5F5F7",
    permanent: "",
    "expand-on-hover": "",
    onMouseleave: _ctx.test,
    class: _normalizeClass(["navigation-scrollbar", _ctx.rail ? 'hide' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, { density: "compact" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            class: "w-100",
            to: "/"
          }, {
            default: _withCtx(() => [
              (_ctx.rail)
                ? (_openBlock(), _createBlock(_component_v_img, {
                    key: 0,
                    class: "mt-3 pa-2 ml-auto",
                    src: _ctx.smallLogo,
                    height: "30"
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_v_img, {
                    key: 1,
                    class: "mt-3 pa-2 cpi-logo",
                    src: _ctx.rail ? _ctx.smallLogo : _ctx.bigLogo,
                    height: "30"
                  }, null, 8, ["src"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!_ctx.rail)
        ? (_openBlock(), _createBlock(_component_v_list_item, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.username), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_list, {
        nav: "",
        density: "compact",
        rounded: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            class: "rounded-lg py-3 main-menu-item",
            density: "compact",
            title: "Меню",
            "prepend-icon": "mdi-menu",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rail = !_ctx.rail))
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (!menuItem.subLinks)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    class: "rounded-lg py-3 main-menu-item",
                    density: "compact",
                    title: menuItem.title,
                    "prepend-icon": menuItem.icon,
                    value: menuItem.value,
                    to: menuItem.link,
                    onClick: _ctx.clear,
                    link: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "bottom"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(menuItem.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["title", "prepend-icon", "value", "to", "onClick"]))
                : (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 1,
                    value: menuItem.value
                  }, {
                    activator: _withCtx(({props}) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: menuItem.title,
                        "prepend-icon": menuItem.icon
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(menuItem.title), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1040, ["title", "prepend-icon"])
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.subLinks, (submenu, i) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          key: i,
                          title: submenu.title,
                          "prepend-icon": submenu.icon,
                          to: submenu.link,
                          value: submenu.title,
                          onClick: _ctx.clear,
                          link: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(submenu.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["title", "prepend-icon", "to", "value", "onClick"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["value"]))
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["rail", "onMouseleave", "class"]))
}