import BaseApiService from '@/services/BaseApiService'
import {UserProfile} from '@/types/UserProfile'
import {BaseData} from '@/types/core/BaseData'

export default class UserProfileApiService extends BaseApiService {
  apiPath = '/profile'

  async get(): Promise<BaseData<UserProfile>> {
    try {
      const {data} = await this.axiosInstanceMain.get<BaseData<UserProfile>>(
        this.apiPath
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async update(userProfile: UserProfile): Promise<BaseData<UserProfile>> {
    try {
      const {data} = await this.axiosInstanceFormData.post<
        BaseData<UserProfile>
      >(this.apiPath, this.convertToFormData(userProfile))
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
  convertToFormData(profile: UserProfile): FormData {
    const formData = new FormData()
    formData.append('_method', 'PUT')
    profile.formDataFiles?.forEach((value) => {
      formData.append('files[]', value)
    })
    return formData
  }
}
