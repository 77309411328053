import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {LinearPoint} from '@/types/basketLine/LinearPoint'
import {ProductServiceDict} from '@/types/ProductServiceDict'
import apiMain from '@/http-common'

export type APIResponseDict =
  | {error?: null; data?: null}
  | {error?: unknown; data?: null}
  | {error?: unknown; data?: LinearPoint}

export type APIResponseProductService =
  | {error?: null; data: ProductServiceDict[]}
  | {error?: unknown; data: ProductServiceDict[]}
  | {error: unknown; data?: null}

const api: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_MAIN_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

export default class BaseLandingApiService {
  protected axiosInstance: AxiosInstance
  protected axiosInstancePrivate: AxiosInstance

  constructor() {
    this.axiosInstance = api
    this.axiosInstancePrivate = apiMain.apiMain
  }

  async getLinear(): Promise<APIResponseDict> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseDict>(
        '/report/getAllCostChart'
      )
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }

  async getProductCostChart(productId: string): Promise<APIResponseDict> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseDict>(
        `/report/getProductCostChart/${productId}`
      )
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }

  async getProductService(): Promise<APIResponseProductService> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseProductService>(
        '/report/getProductServiceDict'
      )
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }
}
