import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import api from '@/http-common'

export default class BaseApiService {
  protected axiosInstance: AxiosInstance
  protected axiosInstanceMain: AxiosInstance
  protected axiosInstanceFormData: AxiosInstance

  constructor() {
    this.axiosInstance = api.apiClient
    this.axiosInstanceMain = api.apiMain
    this.axiosInstanceFormData = api.apiMainFormData
  }

  protected async axiosCall<T>(config: AxiosRequestConfig) {
    try {
      const {data} = await this.axiosInstance.request<T>(config)
      return data
    } catch (error) {
      return [error]
    }
  }
}
