
import {defineComponent, onMounted, watch, ref, computed, Ref} from 'vue'
import CPINavigationDrawer from '@/components/elements/CPINavigationDrawer.vue'
import CPIHeader from '@/components/layout/CPIHeader.vue'
import {Setting} from '@/types/Setting'
import usePeriodTypes from '@/uses/usePeriodTypes'
import ReportApiService from '@/services/ReportApiService'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {DialogWrapper} from 'vue3-promise-dialog'
import {BreadcumbData} from '@/types/core/BreadcumbData'
import CPIErrorDialog from '@/components/elements/errors/CPIErrorDialog.vue'

export default defineComponent({
  name: 'App',
  components: {
    CPIHeader,
    CPINavigationDrawer,
    DialogWrapper,
    CPIErrorDialog,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const reportApiService: ReportApiService = new ReportApiService()
    const {periodTypes} = usePeriodTypes()
    const prevRoute = ref('/')
    const errorDialog = ref(false)
    const errorText = ref('errorText')
    watch(
      () => route.path,
      (newValue, oldValue) => {
        console.log(oldValue)
        //if (prevRoute.value !== 'invoices') return
        prevRoute.value = oldValue
      }
    )
    watch(
      () => store.getters.errorDialog,
      (newValue, oldValue) => {
        console.log('errorDialog', newValue)
        errorDialog.value = store.getters.errorDialog
        errorText.value = store.getters.errorText
        console.log(errorText.value)
      }
    )
    const breadCrumbsItems = computed(() => {
      let breadCumb = [
        {
          title: 'Назад',
          disabled: false,
          href: prevRoute.value,
        },
      ] as BreadcumbData[]
      if (store.getters.breadcumbItems != null)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        breadCumb = breadCumb.concat(
          store.getters.breadcumbItems as BreadcumbData[]
        )
      return breadCumb.concat([
        {
          title: store.getters.pageTitle,
          disabled: true,
          href: 'breadcrumbs_link_1',
        },
      ])
    })
    const getDefaultCalculationType = async () => {
      const response = await reportApiService.getDefaultCalculationInfo()

      if (response.error !== null) {
        console.log(response.error)
        return
      }

      const defaultCalculationType = periodTypes.value.find((element) => {
        return element.type === +(response.data as Setting).value
      })
      await store.dispatch('updateGraphPeriodType', defaultCalculationType)
    }

    onMounted(async () => {
      await getDefaultCalculationType()
    })
    return {
      store,
      breadCrumbsItems,
      errorDialog,
      errorText,
      //
    }
  },
})
