import {ReportDates} from '@/types/basketLine/ReportDates'
import {BasketRequest} from '@/types/basketLine/BasketRequest'
import ReportApiService from '@/services/ReportApiService'
import {GraphPeriodTypes} from '@/enums/GraphPeriodTypes'
import {Setting} from '@/types/Setting'
import useGraphPeriodTypes from '@/uses/useGraphPeriodTypes'
import {Basket} from '@/types/basketLine/Basket'

interface GraphState {
  periodTypeLoaded: boolean
  basketNew: BasketRequest
  reportDatesNew: ReportDates
  graphPeriodTypeNew: GraphPeriodTypes
}
const reportApiService = new ReportApiService()

export const graph = {
  state: {
    basketNew: {
      id: '',
      category_id: null,
      product_and_service_id: null,
    },
    reportDatesNew: new ReportDates(),
    graphPeriodTypeNew: false,
  },
  getters: {
    basketNew: function (state: GraphState): BasketRequest {
      return state.basketNew
    },
  },
  mutations: {
    UPDATE_BASKET_NEW(state: GraphState, payload: BasketRequest) {
      state.basketNew = payload
    },
  },
  actions: {
    async fetchDefaultCalculation({state, commit, rootState}: any) {
      if (state.graphPeriodTypeNew) return

      const {graphButtons} = useGraphPeriodTypes()
      const response = await reportApiService.getDefaultCalculationInfo()
      if (response.error === null) {
        const setting = response.data as Setting
        const graphPeriodType = graphButtons.find(
          (element) => element.type === +setting.value
        )
        commit('UPDATE_GRAPH_PERIOD_TYPE', graphPeriodType)
      }
    },
    async fetchBasketWeight({state, commit, rootState}: any) {
      const response = await reportApiService.getBasketsService()

      if (response.error === null) {
        const defaultBasket = (response.data as Basket[]).find(
          (basket: Basket) => basket.is_default
        ) as Basket
        commit('UPDATE_BASKET_DATA', response.data)
        commit('UPDATE_BASKET', {
          id: defaultBasket.id,
          category_id: null,
          product_and_service_id: null,
        })
        commit('UPDATE_BASKETNAME', defaultBasket.name)
      }
    },
  },
}
