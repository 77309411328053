import {Ref, ref} from 'vue'
import {GraphPeriodTypes} from '@/enums/GraphPeriodTypes'
import {GraphButton} from '@/types/basketLine/GraphButton'
import {useI18n} from 'vue-i18n'

export default function () {
  const {t} = useI18n()
  const periodTypes: Ref<GraphButton[]> = ref([
    {
      text: '1 D',
      disabled: false,
      type: GraphPeriodTypes.CurrentDayToPrevDay,
      description: t('$vuetify.period_types.to_previous_day'),
    },
    {
      text: '1 W',
      disabled: false,
      type: GraphPeriodTypes.ToCorrespondingMonthPrevWeek,
      description: t('$vuetify.period_types.to_previous_week'),
    },
    {
      text: 'MoM',
      disabled: false,
      type: GraphPeriodTypes.CurrentMonthToPrevMonth,
      description: t('$vuetify.period_types.to_previous_month'),
    },
    {
      text: 'MTD',
      disabled: false,
      type: GraphPeriodTypes.EndMonthToBeginMonth,
      description: t('$vuetify.period_types.to_month_begin'),
    },
    {
      text: 'YTD',
      disabled: false,
      type: GraphPeriodTypes.ToYearBegin,
      description: t('$vuetify.period_types.to_year_begin'),
    },
    {
      text: 'SPLY',
      disabled: false,
      type: GraphPeriodTypes.ToCorrespondingMonthPrevYear,
      description: t('$vuetify.period_types.to_previous_year'),
    },
  ])

  return {periodTypes}
}
