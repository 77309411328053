import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {useKeycloak, getKeycloak} from '@baloise/vue-keycloak'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "core" */ '@/views/BaseView.vue'),
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          isPublic: true,
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/HomeView.vue'),
      },
      {
        path: 'about',
        name: 'about',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/AboutView.vue'),
      },
      {
        path: 'users',
        name: 'users',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/user/ListView.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/user/ProfileView.vue'),
      },
      {
        path: 'categories',
        name: 'categories',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/category/ListView.vue'
          ),
      },
      {
        path: 'category/',
        name: 'parent',
        meta: {
          isPublic: false,
        },
        component: () => import('@/views/catalog/ParentView.vue'),
        children: [
          {
            path: ':category/list',
            name: 'foodstuffs',
            meta: {
              isPublic: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "core" */ '@/views/catalog/ListView.vue'
              ),
          },
          {
            path: ':category',
            name: 'product',
            meta: {
              isPublic: false,
            },
            component: () => import('@/views/catalog/ProductListView.vue'),
          },
          {
            path: 'product/:id',
            name: 'productDetail',
            meta: {
              isPublic: false,
            },
            component: () => import('@/views/catalog/ProductView.vue'),
          },
        ],
      },
      {
        path: 'category/:id/product/:product_service_id',
        name: 'catalog_product',
        meta: {
          isPublic: false,
          back_route: {
            name: 'sub_catalog',
            params: {
              id: 'id',
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/catalog/product/ListView.vue'
          ),
      },
      {
        path: 'category/:id/category_detail',
        name: 'category_detail',
        meta: {
          isPublic: false,
          back_route: {
            name: 'category',
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/category/ProductService/ListView.vue'
          ),
      },
      {
        path: 'category/:id/category_detail/:product_service_id',
        name: 'product_service_cost_list_category_detail',
        meta: {
          isPublic: false,
          back_route: {
            name: 'category_detail',
            params: {
              id: 'id',
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_service_cost/ListView.vue'
          ),
      },
      {
        path: 'products_and_services',
        name: 'products_and_services',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/category/ProductService/ListView.vue'
          ),
      },
      {
        path: 'product_and_services/:product_service_id',
        name: 'product_service_cost_list',
        meta: {
          isPublic: false,
          back_route: {
            name: 'products_and_services',
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_service_cost/ListView.vue'
          ),
      },
      {
        path: 'product_and_services/:product_service_id/:product_and_service_fact_costs',
        name: 'product_service_fact_costs_list',
        meta: {
          isPublic: false,
          back_route: {
            name: 'product_service_cost_list',
            params: {
              id: 'product_service_id',
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_service_cost/FactCostListView.vue'
          ),
      },
      {
        path: 'brands',
        name: 'brands',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/brands/ListView.vue'),
      },
      {
        path: 'packaging',
        name: 'packaging',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/packaging/ListView.vue'
          ),
      },
      {
        path: 'brands/:id',
        name: 'brand_detail',
        meta: {
          isPublic: false,
          back_route: {
            name: 'brands',
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/brands/DetailsView.vue'
          ),
      },
      {
        path: 'organization',
        name: 'organization',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/organization/ListView.vue'
          ),
      },
      {
        path: 'region',
        name: 'region',
        meta: {
          isPublic: false,
        },
        component: () => import('@/views/region/ListView.vue'),
      },
      {
        path: 'product_brand',
        name: 'product_brand',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_brand/ListView.vue'
          ),
      },
      {
        path: 'product_brand_data',
        name: 'product_brand_data',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_brand/ListDataView.vue'
          ),
      },
      {
        path: 'product_brand_data/:id/source',
        name: 'product_brand_source',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_brand/source/ListView.vue'
          ),
      },
      {
        path: 'product_brand_countries',
        name: 'product_brand_countries',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/product_brand/countries/ListView.vue'
          ),
      },
      {
        path: 'basket',
        name: 'basket',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/basket/ListView.vue'),
      },
      {
        path: 'basket/create/:id/:type',
        name: 'basket_create',
        meta: {
          isPublic: false,
          back_route: {
            name: 'basket',
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/basket/CreateView.vue'
          ),
      },
      {
        path: 'basket/edit/:id',
        name: 'basket_edit',
        meta: {
          isPublic: false,
          back_route: {
            name: 'basket',
          },
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/basket/EditView.vue'),
      },
      {
        path: 'basket/:id',
        name: 'basket_detail',
        meta: {
          isPublic: false,
          back_route: {
            name: 'basket',
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/basket/DetailView.vue'
          ),
      },
      {
        path: 'basket/:basket_id/:basket_item_id',
        name: 'basket_item_detail',
        meta: {
          isPublic: false,
          back_route: {
            name: 'basket_detail',
            params: {
              id: 'basket_id',
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/basket/ItemDetailView.vue'
          ),
      },
      {
        path: 'basket/:basket_id/:basket_item_id/brands',
        name: 'basket_item_brands',
        meta: {
          isPublic: false,
          back_route: {
            name: 'basket_detail',
            params: {
              id: 'basket_id',
            },
          },
        },
        component: () =>
          import(/* webpackChunkName: "core" */ '@/views/basket/BrandView.vue'),
      },
      {
        path: 'basket_calculation_info',
        name: 'basket_calculation_info',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/basket_calculation_info/ListView.vue'
          ),
      },
      {
        path: 'get-data-from-integrations',
        name: 'get-data-from-integrations',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/product_service_cost/IntegrationsListView.vue'
          ),
      },
      {
        path: 'not-updated-data',
        name: 'not-updated-data',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/not_updated_data/ListView.vue'
          ),
      },
      {
        path: 'settings',
        name: 'settings',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/settings/ListView.vue'
          ),
      },
      {
        path: 'product-service-cost-sandbox',
        name: 'product-service-cost-sandbox',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/product_service_cost_sandbox/ListView.vue'
          ),
      },
      {
        path: 'main-info',
        name: 'main-info',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/main_info/ListView.vue'
          ),
      },
      {
        path: 'methodology-info',
        name: 'methodology-info',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/methodology_info/ListView.vue'
          ),
      },
      {
        path: 'currency-rate',
        name: 'currency-rate',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/currency_rate/ListView.vue'
          ),
      },
      {
        path: 'parsing-data-info',
        name: 'parsing-data-info',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/parsing_data_info/ListView.vue'
          ),
      },
      {
        path: 'on_boarding',
        name: 'OnBoarding',
        meta: {
          isPublic: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "core" */ '@/views/on_boarding/ListView.vue'
          ),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      isPublic: false,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/LoginView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

router.beforeEach(async (to, from, next) => {
  const {isAuthenticated, isPending} = useKeycloak()
  while (isPending.value) {
    await sleep(100)
  }

  if (to.meta.isPublic) {
    next()
  } else {
    const keycloak = getKeycloak()

    if (!isAuthenticated.value) {
      keycloak.login()
    } else {
      next()
    }
  }
})

export default router
