import {Basket} from '@/types/basketLine/Basket'
import {BaseData} from '@/types/BaseData'
import {ReportDates} from '@/types/basketLine/ReportDates'
import {LinearPoint} from '@/types/basketLine/LinearPoint'
import {LinearGraphType} from '@/types/product_service/LinearGraphType'
import {BasketRequest} from '@/types/basketLine/BasketRequest'
import {TopReportRequest} from '@/types/basketLine/TopReportRequest'
import {ChartProductService} from '@/types/basketLine/ChartProductService'
import {LinearChartResponseData} from '@/types/basketLine/LinearChartResponseData'
import BaseLandingApiService from '@/services/BaseLandingApiService'
import {Setting} from '@/types/Setting'
import {ProductServiceCostsCompare} from '@/types/basketLine/ProductServiceCostsCompare'
import PriceOrder from '@/enums/PriceOrder'
import useDateFormats from '@/uses/useDateFormats'
const {formatDateToApi} = useDateFormats()

export default class ReportApiService extends BaseLandingApiService {
  async getTopProductServices(
    category: string,
    risen = 'desc'
  ): Promise<BaseData<ChartProductService>> {
    try {
      const {data} = await this.axiosInstance.get<
        BaseData<ChartProductService>
      >(`/report/diff-category/${category}/${risen}`)
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getTopProductServicesByBasketId(
    basket: BasketRequest,
    topReportRequest: TopReportRequest
  ): Promise<BaseData<ChartProductService>> {
    try {
      const {data} = await this.axiosInstance.get<
        BaseData<ChartProductService>
      >(`/report/top-low-from-basket/${basket.id}`, {
        params: {
          report_date_begin: formatDateToApi(
            topReportRequest.report_date_begin
          ),
          report_date_end: formatDateToApi(topReportRequest.report_date_end),
          risen: topReportRequest.risen,
          category_id: basket.category_id,
        },
      })
      console.log(data.data)
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getProductServiceCostsCompareToOrganizations(
    priceOrder: PriceOrder,
    date: Date,
    count: number
  ): Promise<BaseData<ProductServiceCostsCompare>> {
    try {
      const {data} = await this.axiosInstancePrivate.get<
        BaseData<ProductServiceCostsCompare>
      >(`/private-report/get-top-low-compare-to-organizations/`, {
        params: {
          date: date.toLocaleDateString('en-CA'),
          count: count,
          price_order: priceOrder,
        },
      })
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getBasketsService(): Promise<BaseData<Basket[]>> {
    try {
      const {data} = await this.axiosInstancePrivate.get<BaseData<Basket[]>>(
        '/report/getBasketsWeight'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getBasketsPriceChangeService(
    basket: BasketRequest,
    graphType = 1,
    reportDates = new ReportDates()
  ): Promise<LinearChartResponseData<LinearPoint>> {
    try {
      const {data} = await this.axiosInstance.get<
        LinearChartResponseData<LinearPoint>
      >(`report/get-cpi-graphs/${basket.id}/${graphType}`, {
        params: {
          base_date_begin: formatDateToApi(reportDates.base_date_begin),
          base_date_end: formatDateToApi(reportDates.base_date_end),
          report_date_begin: formatDateToApi(reportDates.report_date_begin),
          report_date_end: formatDateToApi(reportDates.report_date_end),
          category_id: basket.product_and_service_id
            ? null
            : basket.category_id,
          product_and_service_id: basket.product_and_service_id,
        },
      })
      return {error: null, data: data.data, meta: data.meta, id: data.id}
    } catch (error) {
      return {error: error, data: null, meta: null}
    }
  }

  async getProductsPriceChangeService(
    id: string
  ): Promise<BaseData<LinearGraphType>> {
    try {
      const {data} = await this.axiosInstancePrivate.get<
        BaseData<LinearGraphType>
      >(`product_service_list/product_brand_info/${id}`, {})
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getOrganizationsPriceChangeService(
    id: string,
    organizationId: string
  ): Promise<BaseData<LinearGraphType>> {
    try {
      const {data} = await this.axiosInstancePrivate.get<
        BaseData<LinearGraphType>
      >(`product-brands/${id}/get-fact-cost-info`, {
        params: {
          organization_id: organizationId,
        },
      })
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getPrivateCpiGraphs(
    id: string,
    graphType = 1,
    reportDates = new ReportDates()
  ): Promise<LinearChartResponseData<LinearPoint>> {
    try {
      const {data} = await this.axiosInstancePrivate.get<
        LinearChartResponseData<LinearPoint>
      >(`private-report/get-cpi-graphs/${id}/${graphType}`, {
        params: {
          base_date_begin: formatDateToApi(reportDates.base_date_begin),
          base_date_end: formatDateToApi(reportDates.base_date_end),
          report_date_begin: formatDateToApi(reportDates.report_date_begin),
          report_date_end: formatDateToApi(reportDates.report_date_end),
        },
      })
      return {error: null, data: data.data, meta: data.meta}
    } catch (error) {
      return {error: error, data: null, meta: null}
    }
  }

  async getDefaultCalculationInfo(): Promise<BaseData<Setting>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<Setting>>(
        '/report/get-default-calculation-type'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
