// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import {createVuetify} from 'vuetify'
import {createVueI18nAdapter} from 'vuetify/locale/adapters/vue-i18n'
import {useI18n} from 'vue-i18n'

import createI18n from './i18n'

export default createVuetify({
  components,
  directives,
  locale: createVueI18nAdapter({
    i18n: createI18n,
    useI18n,
  }),
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#72BF44',
        },
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
