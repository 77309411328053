import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import {loadFonts} from '@/plugins/webfontloader'
import i18n from '@/plugins/i18n'
import {vueKeycloak} from '@baloise/vue-keycloak'
import {createMetaManager, plugin as metaPlugin} from 'vue-meta'
import {PromiseDialog} from 'vue3-promise-dialog'
import Maska from 'maska'
import UUID from 'vue3-uuid'

loadFonts()

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(PromiseDialog)
  .use(vuetify)
  .use(Maska)
  .use(UUID)
  .use(vueKeycloak, {
    config: {
      url: process.env.VUE_APP_SSO_AUTH_URL,
      realm: process.env.VUE_APP_SSO_REALM,
      clientId: process.env.VUE_APP_SSO_CLIENT,
    },
    initOptions: {
      onLoad: 'login-required',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
    },
  })
  .use(createMetaManager())
  .use(metaPlugin)
app.config.errorHandler = (err, instance, info) => {
  console.log(err, instance, info)
  // handle error, e.g. report to a service
}

router.isReady().then(() => app.mount('#app'))
