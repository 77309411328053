
import {defineComponent, computed, WritableComputedRef} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'

export default defineComponent({
  name: 'CPIErrorDialog',
  props: {
    modelValue: Boolean,
    text: String,
    title: String,
  },
  setup(props, {emit}) {
    const {t} = useI18n()
    const store = useStore()
    const dialogActive: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return props.modelValue
      },
      async set(newValue: boolean): Promise<void> {
        console.log('update:modelValue', newValue)
        if (!newValue) {
          emit('update:modelValue', newValue)
          if (!newValue) {
            await store.dispatch('clearError')
          }
        }
      },
    })
    const errorTitle = computed(() =>
      props.title ? props.title : t('$vuetify.base.error')
    )

    return {
      dialogActive,
      t,
      errorTitle,
    }
  },
})
