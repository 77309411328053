export default {
  "$vuetify": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n русский!!"])},
    "noDataText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных"])},
    "analytic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
      "fields": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронный адрес"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактный телефон"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])}
      },
      "roles": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Админ"])}
      },
      "statuses": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
        "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокирован"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален"])}
      },
      "deactivate_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно деактивировать пользователя?"])},
      "activate_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно активировать пользователя?"])},
      "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])}
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профайл"])},
      "change_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить аватар"])}
    },
    "dictionary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справочники"])}
    },
    "onBoarding": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онбординг"])}
    },
    "catalog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каталог"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовольственные товары"])},
      "nonfood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непродовольственные товары"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])}
    },
    "category": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "fields": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иконка"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Родительская категория"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод"])},
        "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])}
      },
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить категорию ", _interpolate(_named("name")), "?"])}
    },
    "btn": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
      "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать"])},
      "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])},
      "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
      "show_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть график"])},
      "export_excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузить Excel"])}
    },
    "validation": {
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " обязательно"])},
      "must_valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("input")), " должен быть валидным"])},
      "must_match": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input1")), " должен соответсвовать полю ", _interpolate(_named("input2"))])},
      "must_contain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " должно содеражть хотя бы один ", _interpolate(_named("symbol")), " символ"])},
      "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " должно быть длинной минимум ", _interpolate(_named("min")), " символов"])},
      "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " должно быть длинной максимум ", _interpolate(_named("max")), " символов"])},
      "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " должно быть длинной ", _interpolate(_named("length")), " символов"])},
      "special_char": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " должно содержать минимум один симовл ", _interpolate(_named("symbols"))])},
      "uppercase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " должно содержать минимум одиу заглавную букву"])},
      "sumTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сумма полей ", _interpolate(_named("input")), " не должно превышать ", _interpolate(_named("max"))])},
      "equalsTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сумма полей ", _interpolate(_named("input")), " должно быть равно ", _interpolate(_named("equals"))])},
      "not_calculated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("input")), " не просчитано"])}
    },
    "main_page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])}
    },
    "about_page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])}
    },
    "brands": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд товара"])},
      "fields": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИН"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "is_available_for_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно для выбора"])}
      },
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить бренд ", _interpolate(_named("name")), "?"])}
    },
    "packaging": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип Упаковки"])},
      "fields": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИН"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "is_available_for_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно для выбора"])}
      },
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить тип упаковки ", _interpolate(_named("name")), "?"])}
    },
    "organization": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организации"])},
      "fields": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИН"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "is_available_for_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно для выбора"])}
      },
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить огранизацию ", _interpolate(_named("name")), "?"])}
    },
    "region": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регионы"])},
      "fields": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "code_kato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код КАТО"])}
      },
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить регион ", _interpolate(_named("name")), "?"])}
    },
    "not_updated_data": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необновляемые данные"])},
      "fields": {
        "organization_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование организации"])},
        "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование бренда"])},
        "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баркод"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего изменения"])}
      }
    },
    "base": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
    },
    "product_brand": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товары и услуги"])},
      "title_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны по товарам и услугам"])},
      "fields": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баркод"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
        "standard_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный вес"])},
        "standard_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартная цена"])},
        "fact_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактическая цена"])},
        "fact_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический вес"])},
        "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
        "associated_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соединенные товары"])}
      },
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить брэнд ", _interpolate(_named("name")), "?"])}
    },
    "product_brand_data": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детализация товаров и услуг"])},
      "title_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны по товарам и услугам"])},
      "fields": {
        "category_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
        "product_brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование товара"])},
        "product_brand_barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баркод"])},
        "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
        "packaging_type_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип упаковки"])},
        "standard_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартная цена"])},
        "fact_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактическая цена"])},
        "fact_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический вес"])},
        "standard_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный Вес"])},
        "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица Измерения"])}
      },
      "btn": {
        "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источники"])}
      },
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить брэнд ", _interpolate(_named("name")), "?"])}
    },
    "product_service": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подкатегории"])},
      "fields": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баркод"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иконка"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "standard_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный вес"])},
        "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица измерения"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
        "yearWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес по годам"])},
        "yearWeightWithName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("product_service")), " - вес по годам"])}
      },
      "weight": {
        "fields": {
          "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
          "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес"])}
        },
        "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить вес за ", _interpolate(_named("year")), " год?"])}
      }
    },
    "get-data-from-integrations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные с интеграций"])}
    },
    "product_service_cost": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены на товары и услуги"])},
      "fields": {
        "productBrandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование марки товара"])},
        "productBrandBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баркод марки товара"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
        "standardWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный вес"])},
        "factWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический вес"])},
        "standardPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартная цена"])},
        "factPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактическая цена"])}
      },
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить цены товаров ", _interpolate(_named("name")), "?"])},
      "factCostList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена реализации по дням"])},
      "fact": {
        "fields": {
          "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
          "product_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
          "factCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена реализации"])},
          "calcCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена за станд. ед."])}
        },
        "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить фактическую цену за ", _interpolate(_named("day")), "?"])}
      }
    },
    "product_service_cost_sandbox": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Песочница данных"])},
      "showHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать скрытые"])},
      "showIsVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать активные"])},
      "jaro_winkler_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaro-Winkler поиск"])},
      "exact_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точный поиск"])},
      "fields": {
        "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование бренда"])},
        "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баркод"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
        "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица измерения"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
        "standard_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный вес"])},
        "fact_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический вес"])},
        "product_and_service_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория товара/услуги"])},
        "cityCodeKato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код города/области"])}
      }
    },
    "basket_calculation_info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация по расчетам в корзине"])},
      "fields": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])}
      }
    },
    "basket": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина"])},
      "fields": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "isPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость"])},
        "is_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор по умолчанию"])},
        "show_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показ цены"])},
        "organization_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
        "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])}
      },
      "dialog": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление корзины "])},
        "choose_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вес товара"])}
      },
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный"])},
      "not_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непубличный"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить корзину ", _interpolate(_named("name")), "?"])},
      "default_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно сделать по умолчанию корзину ", _interpolate(_named("name")), "?"])},
      "recalculate_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пересчитать вес"])},
      "recalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перерасчет"])},
      "auto-recalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто-перерасчет"])},
      "do_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать корзиной по умолчанию"])},
      "preview_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр графиков"])},
      "your_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша корзина"])},
      "add_product": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск и выбор товара"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите товар или позицию"])},
        "choosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбранные товары"])},
        "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбрать товары"])}
      },
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя избранная корзина"])}
      }
    },
    "basket_item": {
      "fields": {
        "fact_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический удельный вес (в %)"])},
        "average_base_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средне геом. цена за базисный период"])},
        "average_report_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средне геом. цена за отчетный период"])},
        "change_previous_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изменение ", _interpolate(_named("period")), " (в %)"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удельный вес (в %)"])}
      },
      "change_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение к предыдущему"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение"])},
      "to_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["году"])},
      "to_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяцу"])},
      "to_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дню"])}
    },
    "basket_item_cost": {
      "fields": {
        "organization_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование организации"])},
        "item_brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название бренда внутри товара/услуги"])},
        "item_fact_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический вес"])},
        "item_standard_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный вес"])},
        "base_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена за базисный период"])},
        "base_period_standard_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена за базисный период в пересчете на стан ед."])},
        "report_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена за отчетный период"])},
        "report_period_standard_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена за отчетный период в пересчете на стан ед."])},
        "growth_previous_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рост ", _interpolate(_named("period")), " в %"])}
      },
      "average_previous_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Средне геометрическое изменение ", _interpolate(_named("period"))])}
    },
    "basket_item_brands": {
      "fields": {
        "organization_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование организации"])},
        "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название бренда внутри товара/услуги"])},
        "is_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется ли в рассчётах"])}
      },
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить в рассчёты"])},
      "do_not_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не включать в рассчёты"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
      "fields": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])}
      }
    },
    "mainInfos": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные данные"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активная"])},
      "no_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не активная"])},
      "fields": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "index_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс"])},
        "comparison_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип сравнения"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
      }
    },
    "methodology_info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Методология"])},
      "fields": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])}
      }
    },
    "parsing_data_info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика по источникам"])}
    },
    "currency_rate": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы валют"])},
      "fields": {
        "on_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])}
      },
      "currency": {
        "fields": {
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])}
        }
      }
    },
    "on_boarding": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Он бординг"])},
      "fields": {
        "order_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядковый номер"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])}
      }
    },
    "parsingDataInfo": {
      "fields": {
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
        "data_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата загрузки"])},
        "cnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во"])}
      }
    },
    "common": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
      "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])}
    },
    "dates": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
      "months": {
        "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Январь"])},
        "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Февраль"])},
        "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Март"])},
        "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апрель"])},
        "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Май"])},
        "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июнь"])},
        "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июль"])},
        "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Август"])},
        "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сентябрь"])},
        "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Октябрь"])},
        "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ноябрь"])},
        "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декабрь"])}
      }
    },
    "period_types": {
      "to_previous_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему дню"])},
      "to_previous_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущей неделе"])},
      "to_previous_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему месяцу"])},
      "to_month_begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К началу месяца"])},
      "to_year_begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К началу года"])},
      "to_previous_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К соответствующему месяцу предыдущего года"])}
    },
    "graphs": {
      "top": {
        "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ 10"])},
        "compare_to_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["товаров по сравнению с другими магазинами"])}
      }
    }
  }
}