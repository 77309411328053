import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cpi-main" }
const _hoisted_2 = { class: "cpi-page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_p_i_navigation_drawer = _resolveComponent("c-p-i-navigation-drawer")!
  const _component_c_p_i_header = _resolveComponent("c-p-i-header")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_DialogWrapper = _resolveComponent("DialogWrapper")!
  const _component_c_p_i_error_dialog = _resolveComponent("c-p-i-error-dialog")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_c_p_i_navigation_drawer),
      _createVNode(_component_c_p_i_header, { class: "cpi-header" }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_breadcrumbs, { items: _ctx.breadCrumbsItems }, {
                      divider: _withCtx(() => [
                        _createVNode(_component_v_icon, { icon: "mdi-chevron-right" })
                      ]),
                      _: 1
                    }, 8, ["items"]),
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.store.getters.pageTitle), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "8",
                  id: "cpi-tools"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_metainfo, null, {
        title: _withCtx(({content}) => [
          _createTextVNode(_toDisplayString(content), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_DialogWrapper, { "transition-attrs": {name: 'ConfirmDialog'} }),
      _createVNode(_component_DialogWrapper, { "transition-attrs": {name: 'NewConfirmDialog'} }),
      _createVNode(_component_c_p_i_error_dialog, {
        modelValue: _ctx.errorDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorDialog) = $event)),
        text: _ctx.errorText
      }, null, 8, ["modelValue", "text"])
    ]),
    _: 1
  }))
}