import {createStore} from 'vuex'
import {GraphPeriodTypes} from '@/enums/GraphPeriodTypes'
import {TopReportDates} from '@/types/basketLine/TopReportDates'
import {ReportDates} from '@/types/basketLine/ReportDates'
import {BasketRequest} from '@/types/basketLine/BasketRequest'
import {BreadcumbData} from '@/types/core/BreadcumbData'
import {graph} from '@/store/modules/graph'

export default createStore({
  state: {
    pageTitle: '',
    profileIcon: '',
    breadcumbItems: [] as BreadcumbData[],
    errorDialog: false,
    errorText: 'an Error',
    rail: false,
    basket: {
      id: '',
      category_id: null,
      product_and_service_id: null,
    },
    graphPeriodType: {
      text: 'SPLY',
      disabled: false,
      type: GraphPeriodTypes.ToCorrespondingMonthPrevYear,
      description: 'К соответствующему месяцу предыдущего года',
    },
    // graphPeriodType: {
    //   text: 'YTD',
    //   disabled: false,
    //   type: GraphPeriodTypes.ToYearBegin,
    //   description: 'к началу года',
    // },
    year: new Date().getFullYear(),
    currency: {
      id: 'bf57bd0f-2a7e-4cfd-bd90-0a736f3eaebd',
      code: 'RUB',
      name: 'Рубль',
    },
    topReportDates: {
      report_date_begin: new Date(new Date().getFullYear(), 0, 1),
      report_date_end: new Date(),
    },
    reportDates: new ReportDates(),
  },
  getters: {
    breadcumbItems: function (state) {
      return state.breadcumbItems
    },
    rail: function (state) {
      return state.rail
    },
    errorDialog: function (state) {
      return state.errorDialog
    },
    errorText: function (state) {
      return state.errorText
    },
    pageTitle: function (state) {
      return state.pageTitle
    },
    profileIcon: function (state) {
      return state.profileIcon
    },
    basket: function (state): BasketRequest {
      return state.basket
    },
    graphPeriodType: function (state) {
      return state.graphPeriodType
    },
    year: function (state) {
      return state.year
    },
    currency: function (state) {
      return state.currency
    },
    topReportDates: function (state): TopReportDates {
      return state.topReportDates
    },
    reportDates: function (state): ReportDates {
      return state.reportDates
    },
  },
  mutations: {
    UPDATE_BREADCUMBS(state, payload) {
      state.breadcumbItems.push(payload)
    },
    SET_RAIL(state, payload) {
      state.rail = payload
    },
    SET_ERROR(state, payload) {
      state.errorDialog = true
      state.errorText = payload
    },
    CLEAR_ERROR(state) {
      state.errorDialog = false
      state.errorText = ''
    },
    CLEAR_BREADCUMBS(state) {
      state.breadcumbItems = []
    },
    UPDATE_TITLE(state, payload) {
      state.pageTitle = payload
    },
    UPDATE_PROFILE_ICON(state, payload) {
      state.profileIcon = payload
    },
    UPDATE_BASKET(state, payload) {
      state.basket = payload
    },
    UPDATE_GRAPH_PERIOD_TYPE(state, payload) {
      state.graphPeriodType = payload
    },
    UPDATE_YEAR(state, payload) {
      state.year = payload
    },
    UPDATE_CURRENCY(state, payload) {
      state.currency = payload
    },
    UPDATE_TOP_REPORT_DATES(state, payload: TopReportDates) {
      state.topReportDates = payload
    },
    UPDATE_REPORT_DATES(state, payload: ReportDates) {
      state.reportDates = payload
    },
  },
  actions: {
    updateBreadcumb(context, payload) {
      if (payload.length !== '') context.commit('UPDATE_BREADCUMBS', payload)
    },
    setRail(context, payload) {
      context.commit('SET_RAIL', payload)
    },
    setError(context, payload) {
      context.commit('SET_ERROR', payload)
    },
    clearError(context) {
      context.commit('CLEAR_ERROR')
    },
    clearBreadcumb(context) {
      context.commit('CLEAR_BREADCUMBS')
    },
    updateTitle(context, payload) {
      if (payload.length !== '') context.commit('UPDATE_TITLE', payload)
    },
    updateProfileIcon(context, payload) {
      if (payload.length !== '') context.commit('UPDATE_PROFILE_ICON', payload)
    },
    updateBasket(context, payload: BasketRequest) {
      context.commit('UPDATE_BASKET', payload)
    },
    updateGraphPeriodType(context, payload) {
      context.commit('UPDATE_GRAPH_PERIOD_TYPE', payload)
    },
    updateYear(context, payload) {
      context.commit('UPDATE_YEAR', payload)
    },
    updateCurrency(context, payload) {
      context.commit('UPDATE_CURRENCY', payload)
    },
    updateTopReportDates(context, payload: TopReportDates) {
      context.commit('UPDATE_TOP_REPORT_DATES', payload)
    },
    updateReportDates(context, payload: ReportDates) {
      context.commit('UPDATE_REPORT_DATES', payload)
    },
  },
  modules: {
    graph,
  },
})
