
import {defineComponent, onMounted, Ref, ref} from 'vue'
import {useKeycloak, getKeycloak} from '@baloise/vue-keycloak'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import UserProfileApiService from '@/services/UserProfileApiService'
import useFileStorage from '@/uses/useFileStorage'
import {UserProfile} from '@/types/UserProfile'

export default defineComponent({
  name: 'CPIHeader',
  setup() {
    const {isAuthenticated, username} = useKeycloak()
    const {t} = useI18n()
    const store = useStore()
    const {getFileFullPath} = useFileStorage()
    const profile: Ref<UserProfile> = ref(new UserProfile())
    const userProfileApiService = new UserProfileApiService()

    const logout = () => {
      const keycloak = getKeycloak()
      keycloak.logout({redirectUri: process.env.VUE_APP_SSO_LOGOUT_URL})
    }

    onMounted(async () => {
      if (isAuthenticated.value) {
        const response = await userProfileApiService.get()
        profile.value = response.data as UserProfile
        await store.dispatch(
          'updateProfileIcon',
          getFileFullPath(profile.value.files?.[0].path as string)
        )
      }
    })

    return {isAuthenticated, username, logout, store, t}
  },
})
