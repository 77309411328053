import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialogActive,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogActive) = $event)),
    class: "Error-Dialog"
  }, {
    default: _withCtx(({isActive}) => [
      _createVNode(_component_v_card, { rounded: "xl" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "red" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "header_title" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "10",
                    class: "header_title_name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.errorTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "2",
                    justify: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "c-p-i-dialog_btn-close",
                        size: "x-small",
                        rounded: "xl",
                        icon: "mdi-close",
                        variant: "flat",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogActive = false))
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: ($event: any) => (isActive.value = false)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('$vuetify.btn.close')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}