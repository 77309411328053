import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_c_p_i_avatar_section = _resolveComponent("c-p-i-avatar-section")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    color: "#F5F5F7",
    density: "compact",
    height: "75",
    elevation: "0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "8",
        class: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_ctx.showBackButton)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.back,
                    class: "px-0",
                    icon: "mdi-arrow-left",
                    variant: "outline"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, { cols: "4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            justify: "space-around",
            align: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "",
                class: "py-2 fill-height"
              }),
              _createVNode(_component_v_col, {
                cols: "2",
                class: "py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_c_p_i_avatar_section)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}