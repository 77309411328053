import axios, {AxiosInstance} from 'axios'
import {getToken, useKeycloak} from '@baloise/vue-keycloak'

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

const apiMain: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_MAIN_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

const apiMainFormData: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_MAIN_URL,
  headers: {
    'Content-type': 'multipart/form-data',
  },
})

apiMainFormData.interceptors.request.use(
  async (config) => {
    const {isAuthenticated} = useKeycloak()
    if (isAuthenticated.value) {
      const token = await getToken()
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-type': 'multipart/form-data',
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiClient.interceptors.request.use(
  async (config) => {
    const {isAuthenticated} = useKeycloak()
    if (isAuthenticated.value) {
      const token = await getToken()
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiMain.interceptors.request.use(
  async (config) => {
    const {isAuthenticated} = useKeycloak()
    if (isAuthenticated.value) {
      const token = await getToken()
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
export default {apiClient, apiMain, apiMainFormData}
