import {useRoute, useRouter} from 'vue-router'
import {BackRouteMeta} from '@/types/meta/BackRouteMeta'
import {onMounted, ref, watch} from 'vue'

export default function () {
  const route = useRoute()
  const router = useRouter()
  const showBackButton = ref(false)
  watch(
    () => route.path,
    () => {
      showBackButton.value = route.meta.back_route !== undefined
    }
  )
  onMounted(() => {
    showBackButton.value = route.meta.back_route !== undefined
  })

  const back = () => {
    const backRouteObj = route.meta.back_route as BackRouteMeta
    if (backRouteObj.params === undefined) {
      router.push({
        name: (route.meta.back_route as BackRouteMeta).name,
      })
    } else {
      const newParams: {
        [k: string]: string
      } = {}

      Object.keys(backRouteObj.params).forEach((key) => {
        newParams[key] = route.params[backRouteObj.params[key]] as string
      })

      router.push({
        name: backRouteObj.name,
        params: newParams,
      })
    }
  }

  return {
    back,
    showBackButton,
  }
}
