
import {defineComponent} from 'vue'
import CPIAvatarSection from '@/components/elements/CPIAvatarSection.vue'
import useRouterBack from '@/uses/useRouterBack'

export default defineComponent({
  name: 'CPIHeader',
  components: {
    CPIAvatarSection,
  },
  setup() {
    const {back, showBackButton} = useRouterBack()

    return {back, showBackButton}
  },
})
