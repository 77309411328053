
import {computed, defineComponent, onMounted, ref, watch, Ref} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
const {isAuthenticated, username, token, roles} = useKeycloak()

import Role from '@/enums/Role'
import {useKeycloak} from '@baloise/vue-keycloak'

export default defineComponent({
  name: 'CPINavigationDrawer',
  setup() {
    const {t} = useI18n()
    const store = useStore()
    const groupopened = ref(true)
    const rail = computed(() => {
      return store.getters.rail
    })
    const clear = () => {
      console.log('clear')
      store.dispatch('clearBreadcumb')
      store.dispatch('setRail', false)
    }
    const menuItemsAll = [
      // {
      //   title: "Trading Up",
      //   icon: "mdi-magnify",
      //   value: "trading",
      //   link: "/about",
      // },
      {
        title: t('$vuetify.analytic.title'),
        icon: 'mdi-sine-wave',
        value: 'home',
        link: '/',
        roles: [Role.ADMIN, Role.MANAGER],
      },
      {
        title: t('$vuetify.user.title'),
        icon: 'mdi-account-outline',
        value: 'users',
        link: '/users',
        roles: [Role.ADMIN, Role.MANAGER],
      },
      {
        title: t('$vuetify.catalog.title'),
        icon: 'mdi-clipboard-list-outline',
        value: 'catalog',
        link: '/category/food/list',
        roles: [Role.ADMIN, Role.MANAGER],
      },
      {
        title: t('$vuetify.dictionary.title'),
        icon: 'mdi-cog-outline',
        value: 'dictionary',
        roles: [Role.ADMIN, Role.MANAGER],
        subLinks: [
          {
            title: t('$vuetify.category.title'),
            icon: 'mdi-clipboard-list-outline',
            value: 'categories',
            link: '/categories',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.product_service.title'),
            icon: 'mdi-tray-full',
            value: 'products_and_services',
            link: '/products_and_services',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.product_brand.title'),
            icon: 'mdi-watermark',
            value: 'product_brand',
            link: '/product_brand',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.product_brand_data.title'),
            icon: 'mdi-watermark',
            value: 'product_brand_data',
            link: '/product_brand_data',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.product_brand.title_countries'),
            icon: 'mdi-watermark',
            value: 'product_brand_countries',
            link: '/product_brand_countries',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.brands.title'),
            icon: 'mdi-domain',
            value: 'brands',
            link: '/brands',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.packaging.title'),
            icon: 'mdi-domain',
            value: 'packaging',
            link: '/packaging',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.organization.title'),
            icon: 'mdi-domain',
            value: 'organization',
            link: '/organization',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.region.title'),
            icon: 'mdi-domain',
            value: 'region',
            link: '/region',
            roles: [Role.ADMIN, Role.MANAGER],
          },
        ],
      },
      {
        title: t('$vuetify.basket.title'),
        icon: 'mdi-basket-outline',
        value: 'basket',
        link: '/basket',
        roles: [Role.ADMIN, Role.MANAGER],
      },
      {
        title: t('$vuetify.product_service_cost_sandbox.title'),
        icon: 'mdi-folder-plus-outline',
        value: 'product-service-cost-sandbox',
        link: '/product-service-cost-sandbox',
        roles: [Role.ADMIN, Role.MANAGER],
      },
      {
        title: t('$vuetify.settings.title'),
        icon: 'mdi-cog-outline',
        value: 'settings',
        roles: [Role.ADMIN, Role.MANAGER],
        subLinks: [
          {
            title: t('$vuetify.basket_calculation_info.title'),
            icon: 'mdi-book-information-variant',
            value: 'basket_calculation_info',
            link: '/basket_calculation_info',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.not_updated_data.title'),
            icon: 'mdi-file-cancel-outline',
            value: 'not-updated-data',
            link: '/not-updated-data',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.settings.title'),
            icon: 'mdi-cog-outline',
            value: 'settings',
            link: '/settings',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.mainInfos.title'),
            icon: 'mdi-information-outline',
            value: 'main-info',
            link: '/main-info',
            roles: [Role.ADMIN, Role.KURSIVE],
          },
          {
            title: t('$vuetify.methodology_info.title'),
            icon: 'mdi-file-sign',
            value: 'methodology-info',
            link: '/methodology-info',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.currency_rate.title'),
            icon: 'mdi-cash-multiple',
            value: 'currency-rate',
            link: '/currency-rate',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.parsing_data_info.title'),
            icon: 'mdi-chart-bar',
            value: 'parsing-data-info',
            link: '/parsing-data-info',
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            title: t('$vuetify.onBoarding.title'),
            icon: 'mdi-cash-multiple',
            value: 'on-boarding',
            link: '/on_boarding',
            roles: [Role.ADMIN, Role.MANAGER],
          },
        ],
      },
      // {
      //   title: t("$vuetify.get-data-from-integrations.title"),
      //   icon: "mdi-arrow-collapse",
      //   value: "get-data-from-integrations",
      //   link: "/get-data-from-integrations",
      //   roles: [Role.ADMIN, Role.MANAGER],
      // },
    ]

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const bigLogo = ref(require('@/assets/icons/CPI.svg'))
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const smallLogo = ref(require('@/assets/icons/CPI-FF-logo.svg'))

    const userExistRoles = (roleList: string[]): boolean => {
      // eslint-disable-next-line
      if (!!token) {
        const groups = parseJwt(token.value).groups
        console.log(roleList)
        console.log(groups)
        console.log(roleList.some((role) => groups.includes(role)))
        return roleList.some((role) => groups.includes(role))
      }
      return false
    }

    const parseJwt = (token: string) => {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      return JSON.parse(jsonPayload)
    }

    const test = () => {
      console.log('yeu')
      groupopened.value = false
      console.log(groupopened.value)
    }

    const test2 = () => {
      console.log('test2')
      groupopened.value = true
      console.log(groupopened.value)
    }

    onMounted(async () => {
      if (isAuthenticated.value) {
        console.log(username)
      }

      menuItems.value = menuItemsAll.filter((menuItem) => {
        return menuItem.roles.some((role) => roles.value.includes(role))
      })
    })

    const menuItems: Ref<any[]> = ref([])

    return {
      rail,
      menuItems,
      bigLogo,
      smallLogo,
      userExistRoles,
      isAuthenticated,
      username,
      roles,
      clear,
      groupopened,
      test,
      test2,
    }
  },
})
